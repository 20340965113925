let checkInputValidate = function(input) {


    let error = "error" + input.attr("id");

    input.siblings(".form__input--error").remove();
    if (input[0].validity.valid) {
        input.parents(".form__input").addClass("is--valid");
        input.parents(".form__input").removeClass("is--invalid");

        input.removeAttr("aria-describedby");
    } else {
        input.parents(".form__input").removeClass("is--valid");
        if (input.parents("form").hasClass("afterFirstValidate")) {
            input.parents(".form__input").addClass("is--invalid");
            input.attr("aria-describedby", error);
            input.after('<span id="' + error + '" class="form__input--error">' + input[0].validationMessage + '</span>')
        }
    }
}
let checkBoxValidate = function(input) {
    let error = "error" + input.attr("id");
    if (input.parents("form").hasClass("afterFirstValidate")) {
        input.siblings(".form__input--error").remove();
        if (input.is(":checked")) {
            input.parents(".form-checkbox").addClass("is--valid");
            input.parents(".form-checkbox").removeClass("is--invalid");
            input.removeAttr("aria-describedby");
        } else {
            input.parents(".form-checkbox").removeClass("is--valid");
            input.parents(".form-checkbox").addClass("is--invalid");
            input.attr("aria-describedby", error);
            input.after("<span id=" + error + "' class='form__input--error'>" + input[0].validationMessage + "</span>")
        }
    }
}

let isEmpty = function(el) {
    ;
    if (el.val() == "") {
        el.parents(".form__input").addClass("empty");
        el.parents(".form__input").removeClass("noempty");
    } else {
        el.parents(".form__input").removeClass("empty")
        el.parents(".form__input").addClass("noempty")
        if (!el[0].validity.valid) {
            el.parents(".form__input").addClass("noempty");
            el.parents(".form__input").removeClass("empty");
        }
    }
}


$('.form__input textarea, .form__input input').bind(' keyup change', function() {
    checkInputValidate($(this));
    isEmpty($(this));
    $(".form-invalid-info").css("display", "none");
});


$('.form__input textarea:required, .form__input input:required').each(function() {
    checkInputValidate($(this));
});
$('.form__input textarea, .form__input input').each(function() {
    isEmpty($(this));
});

$("form input[type='checkbox'][required]").each(function() {
    $(this).siblings("label").prepend("<span class='text--red'>* </span>");
})



function isCaptchaChecked() {
    return grecaptcha && grecaptcha.getResponse().length !== 0;
}
console.log("vvvv");
$("#i_haslo_2").on("paste keyup", function() {

    if ($("#i_haslo_1").val() == $("#i_haslo_2").val()) {
        console.log("kkkkk");
        $("#i_haslo_2").parent().removeClass("is--invalid").find(".form__input--error").remove();
    } else {
        if ($("#i_haslo_2").parent().find(".form-input__error").length == 0) {
             $("#i_haslo_2").parent().removeClass("is--valid").addClass("is--invalid").append("<span class='form__input--error'>Hasła nie są identyczne</span>"); }
    }
})

let formValidate = function(form, namefunction) {

    const $form = form;
    const $btnSubmitt = form.find('.btn-submit');


    $btnSubmitt.click(function(e) {
        const inputsRequired = form.find(".form__input input, .form__input textarea");

        const checkBoxRequired = form.find(".form-checkbox input:required");

        e.preventDefault();
        $form.addClass("afterFirstValidate");


        inputsRequired.each(function() {
            checkInputValidate($(this));
        });


        checkBoxRequired.each(function() {
            checkBoxValidate($(this));

        });

        if ($("input[name='nip']").parents(".form-checkbox").hasClass("is--invalid") || !$("input[name='nip']").attr("required")) {
            $("input[name='nip']").parents(".is--invalid").removeClass(".is--invalid")
        }
        if ($("#i_haslo_1").length && $("#i_haslo_2").length) {
            console.log($("#i_haslo_1").val());
            console.log($("#i_haslo_2").val());
            console.log($("#i_haslo_1").val() != $("#i_haslo_2").val());
            if ($("#i_haslo_1").val() != $("#i_haslo_2").val()) {
                console.log("xxxx");
                console.log($("#i_haslo_2").parent());
                $("#i_haslo_2").parent().removeClass("is--valid").addClass("is--invalid").append("<span class='form__input--error'>Hasła nie są identyczne</span>");
            }
        }

        if ($(".is--invalid").length == 0) {

            if (namefunction == 1) {
                if ($("#del-pay-question input[data-rodzaj='9999']") || $("#del-pay-question input[data-rodzaj='7777']")) {
                    if ($("#del-pay-question input[data-rodzaj='9999']").prop("checked") && !$("#input-paczkomat").val().length) {
                        $("#paczkomat-box button").click();
                    } else {
                        if ($("#del-pay-question input[data-rodzaj='7777']").prop("checked") && !$("#input-ruch").val().length) {
                            $("#ruch-box button").click();

                        } else {
                            form.submit();
                        }
                    }
                } else {
                    form.submit();
                }

            } else {
                namefunction(form);
            }


        } else {
            if (form.find(".is--invalid").length > 0) { form.find(".is--invalid >*")[0].focus(); }

            $(".form-invalid-info").css("display", "inline-block");

        }
    })
}


$(".reguired--star input:not([type='checkbox'],[type='radio']):required").each(function() {
    $(this).parent().addClass("input--star");
})


$(".go-bottom:not('.opinion__form, .powiadom__form, .newsletter__form, .question__form')").each(function() {
    formValidate($(this), 1);
})

const opinionForm = function() {
    let inputs = $(".opinion__form").serialize();
    $.ajax({
        url: '/a/',
        type: "POST",
        data: inputs,
        success: function(data) {
            $(".opinion__form ").before('<p class="info--green">Dziękujemy za dodanie opinii</p>')

            $(".opinion__form").css("display", "none");
        }
    })
}

const powiadomForm = function() {
    let inputs = $(".powiadom__form").serialize();
    $.ajax({
        url: '/a/',
        type: "POST",
        data: inputs,
        success: function(data) {
            $(".powiadom__form ").before('<p class="info--green">Wyślemy powiadomienie, gdy tylko produkt pojawi się w magazynie.</p>')

            $(".powiadom__form").css("display", "none");
        }
    })
}

const questionForm = function() {
    let inputs = $(".question__form").serialize();
    $.ajax({
        url: '/a/',
        type: "POST",
        data: inputs,
        success: function(data) {
            $(".question__form ").before('<p class="info--green">Dziękujemy za pytanie.<br>Odpowiedź wyślemy na podany adres e-mail.</p>')
            $(".question__form").css("display", "none");
        }
    })
}

const newsletterForm = function() {
    let inputs = $(".newsletter__form").serialize();
 
    $.ajax({
        url: '/a/',
        type: "POST",
        data: inputs,
        success: function(data) {

            let className = "info--green";
            if (!data.status) {
                className = "info--red";

            }
            $(".newsletter__form").before('<p class=' + className + '>' + data.komunikat + '</p>')

            $(".newsletter__form").css("display", "none");
        }
    })
}

$(".opinion__form").each(function() {
    formValidate($(this), opinionForm);
})


$(".powiadom__form").each(function() {
    formValidate($(this), powiadomForm);
})

$(".newsletter__form").each(function() {
    formValidate($(this), newsletterForm);
})

$(".question__form").each(function() {
    formValidate($(this), questionForm);
})

$("body").on( "click", ".btn--modal", function() {
    $(".default_dialog form").css("display", "block");

    $(".default_dialog .info--green").remove();

    if ($(this).hasClass("btn--getInfo")) { 
        let id = "";
        if ($(this).parents(".product__parent").length) {
            id = $(this).parents(".product__parent").data("id");
        }
        $(".powiadom__form [name='produkt']").val(id);
    }
})

if ($("textarea").length) {
    let textarea = document.querySelector('textarea');

    textarea.addEventListener('keydown', autosize);

    function autosize() {
        var el = this;
        setTimeout(function() {
            el.style.cssText = 'height:auto; padding:4px';
            // for box-sizing other than "content-box" use:
            // el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
        }, 0);
    }
}

if ($("#customer-2").length) {
    let nip = $("input[name='nip']").parents(".form__input").clone();
    let firma = $("input[name='firma']").parents(".form__input").clone();


    function buyAsCompany() {

        if ($("#customer-2").prop("checked")) {
            $("#fieldset-fv").show();
            $("#info-fv").css("display", "block");
          
            if ($("input[name='nip']").length == 0) {
                $(".fieldset-fv").prepend(nip);
                $(".fieldset-fv").prepend(firma);
            }

            // $(" #info-fv").append(nip);
            // $(" #info-fv").append(firma);
            // $("input[name='nip'], input[name='firma']").parents(".form__input").show();
            $("input[name='nip'], input[name='firma']").attr("required", "required");

        } else {
            $("#info-fv").css("display", "none");
            $("input[name='nip'], input[name='firma']").removeAttr("required", "required");
            $("input[name='nip'], input[name='firma']").parents(".form__input").remove();
            $("#fieldset-fv").hide();
        }
    }
    buyAsCompany();
    $(".cart__customer input").on("change", function() {
        buyAsCompany();
    })
}